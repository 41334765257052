import React from "react"
import { Link } from 'gatsby';

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';

// Image imports
import business from '../images/business-photo.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';

const Business = () => {
  return (
    <Layout>
        <SEO 
            title="Business Lawyer in Kansas City | Mike Martin Law"
            description="Representing businesses since 1985, with wide-ranging experience working with business clients, including small business owners, independent contractors, and even professional musicians."
        />
        <Card>
            <PageHeader 
                title="Business Law"
                image={business}
                alte="Reviewing legal documents"
            />
            <div className="grid col-span-1 sm:grid-cols-12 my-8">
                <div className="sm:col-span-8">
                    <p>Mike Martin has represented businesses since 1985, with wide-ranging experience working with business clients, including small business owners, independent contractors, and even professional musicians.</p>
                    <p>One of Mike’s particular areas of expertise is representing clients in the construction industry, including construction companies, contractors, suppliers and skilled tradespeople.</p>
                    <p className="font-semibold">Businesses turn to Mike Martin Law for a wide range of needs, including:</p>
                    <ul>
                        <li>Business formation, including LLC, corporations, S-corps, partnerships, and sole proprietorships</li>
                        <li>Business and real estate transactions (commercial &amp; residential)</li>
                        <li>Mergers and acquisitions</li>
                        <li>Asset purchase agreements</li>
                        <li>Mechanic’s liens</li>
                        <li>Bond claims</li>
                        <li>Miller Act claims</li>
                        <li>Business conflicts</li>
                        <li>Debt collection</li>
                        <li>Shareholder, member and partner disputes</li>
                    </ul>
                    <h3 className="section-header">Experience that makes a difference</h3>
                    <p>Mike Martin’s long experience as a litigator benefits his business clients, allowing him to successfully represent business interests in civil trials related to contracts, liens, and real estate.</p>
                    <p>Mike also focuses on probate law and frequently deals with difficult probate cases that include business holdings.</p>
                    <p>In addition, Mike’s practice of business law is uniquely informed by his knowledge of business accounting.</p>
                    <p>Before completing his law degree at the University of Kansas, Mike earned his bachelor’s degree in accounting and business administration at KU’s School of Business, and he spent the first two years of his law career working in a tax and securities firm.</p>
                    <p>If you are looking for corporate counsel to handle a variety of legal needs for your business, Mike brings a unique combination of administrative law and courtroom experience to the table.</p>
                    <p>Whether you need a contract lawyer, construction lawyer, real estate attorney, or probate expert to advise and represent your business, Mike has the experience to get results.</p>
                    <p className="font-bold">Do you need an experienced business attorney? <Link to={ROUTES.CONTACT} className="text-secondary underline">Contact Mike Martin today.</Link></p>
                </div>
            </div>
        </Card>
    </Layout>
  )
}

export default Business;