import React from 'react';

const PageHeader = ({image, alt, title}) => {
    return (
        <div className="flex flex-col sm:flex-row gap-x-4 w-full items-center border-b border-gray-200 pb-8">
            <img 
                src={image}
                alt={alt}
                className="col-span-4"
            />
            <h1 className="col-span-8 text-4xl font-bold mt-4 sm:mt-0">{title}</h1>
        </div>
    )
};

export default PageHeader;